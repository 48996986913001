import axios from 'axios';

export interface EventScheduledConfig {
  uid: number;
  priority: number;
  start_date: Date;
  end_date: Date;
  type: string;
  language: string;
  target_uid?: string | null;
  value?: string | null;
  created_datetime: Date;
  updated_datetime: Date;
} 

export default async function getEventScheduledConfig(uid: string) {
  try {
    const idToken = window.sessionStorage.getItem('idToken')
    const res = await axios.get(`${process.env.REACT_APP_REST_SERVER_URL}/events/${uid}/scheduledConfigs`, { headers: { authorization: idToken } });
    return res.data.data as EventScheduledConfig[];
  } catch (err) {
    throw err;
  }
}