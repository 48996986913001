import { Box, Button, InputAdornment } from "@material-ui/core";
// import ContentFilter from "@material-ui/icons/FilterList";
import SearchIcon from '@material-ui/icons/Search';
import React, { useEffect } from 'react';
import { SelectInput, TextInput, useListContext } from 'react-admin';
import { Form } from 'react-final-form';
import { configTypeArray, languageArray } from './constant';

const ConfigFilter = props => {
  return props.context === "button" ? (
    <ConfigFilterButton {...props} />
  ) : (
    <ConfigFilterForm {...props} />
  );
};

const ConfigFilterButton = () => {
  const { showFilter } = useListContext();
  useEffect(() => {
    showFilter('main')
  }, [])
  return (
    <div />
  );
};

const ConfigFilterForm = ({ open }) => {
  const {
    displayedFilters,
    filterValues,
    setFilters,
    hideFilter,
  } = useListContext();

  if (!displayedFilters.main) return null;

  const onSubmit = values => {
    if (Object.keys(values).length > 0) {
      setFilters(values);
    } else {
      setFilters({});
    }
  };

  return (
    <div>
      <Form onSubmit={onSubmit} initialValues={filterValues}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            {/* <Box mt={8} /> */}
            <Box display="flex" alignItems="flex-start" mb={1}>
              <Box component="span" mr={2}>
                {/* Full-text search filter. We don't use <SearchFilter> to force a large form input */}
                <TextInput
                  resettable
                  helperText={false}
                  source="target_uid"
                  label="Search by Target UID"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <SearchIcon color="disabled" />
                      </InputAdornment>
                    )
                  }}
                  alwaysOn
                />
              </Box>
              <Box component="span" mr={2}>
                <SelectInput source="type" choices={configTypeArray} alwaysOn allowEmpty />
              </Box>
              <Box component="span" mr={2}>
                <SelectInput source="language" choices={languageArray} alwaysOn allowEmpty />
              </Box>
              <Box component="span" mr={2} mt={2}>
                <Button variant="outlined" color="primary" type="submit">
                  Filter
                    </Button>
              </Box>
            </Box>
          </form>
        )}
      </Form>
    </div>
  );
};

export default ConfigFilter;