import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import React, { useMemo } from 'react';
import { BooleanField, DateField, ImageField, NumberField, RichTextField, Show, Tab, TabbedShowLayout, TextField } from 'react-admin';
import TextLinkField from '../fields/TextLinkField';
import EventShowTagTable from './EventShowTagTable';
import EventShowActions from './EventShowActions';

const EventCategories = ({ record }) => {
  const tags = useMemo(() => {
    return (record.tags || []).filter(t => t.uid && t.name && t.type).map(t => [t.uid, t.name, t.type])
  }, [record.tags])
  return (
    <div>
      <label className="MuiFormLabel-root MuiInputLabel-root RaLabeled-label-52 MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense">Categories</label>
      <EventShowTagTable
        // headers={['ID', 'NAME', 'TYPE', 'CREATED DATETIME', 'UPDATED DATETIME']}
        // rows={(record.tags || []).map(tag => [tag.id || tag.uid, tag.name, tag.type, tag.created_datetime, tag.updated_datetime])}
        headers={['ID', 'NAME', 'TYPE']}
        rows={tags}
      />
    </div>
  )
};

const IdField = ({ record = {} }) => {
  return (
    <div>
      <label className="MuiFormLabel-root MuiInputLabel-root RaLabeled-label-52 MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense">
        ID
      </label>
      <a href={`${process.env.REACT_APP_WEBSITE_URL}/${record.language === 'EN' ? 'en' : 'zh'}/events/${record.id}`} target="_blank">{record.id}</a>
    </div>
  )
};

const ScoreBreakDownField = ({ record = {} }) => <div>{(record.scoreBreakdown || '').split('\n').map(val => <p>{val}</p>)}</div>;

const GalleryImagesField = ({ record = {} }) => <div>
  <label className="MuiFormLabel-root MuiInputLabel-root RaLabeled-label-52 MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense">
    Gallery
  </label>
  {(record.gallery_images || '').split(',').map(val => <div><img src={val} /></div>)}
</div>;


const useStyles = makeStyles((theme) => ({
  actionButtonContainer: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export const EventShow = (props) => {

  return (
    <Show actions={<EventShowActions />} {...props}>
      <TabbedShowLayout>
        <Tab label="basic">
          <TextField source="status" />
          <IdField label="Id" source="id" />
          <TextField fullWidth source="slug" helperText="user-friendly ID (shown in the URL)" />
          <TextField fullWidth source="title" />
          <TextField fullWidth multiline source="excerpt" />
          <RichTextField fullWidth multiline source="desc" />
          <DateField source="start_datetime" showTime />
          <DateField source="end_datetime" showTime />
          <NumberField source="price" />
          <TextField fullWidth multiline source="ticketingInformation" />
          <TextField source="currency" />
          <TextField fullWidth source="event_url" />
          <TextField source="platform_source" />
          <TextField source="language" />
          <DateField source="updated_datetime" showTime />
          <DateField source="created_datetime" showTime />
        </Tab>
        <Tab label="date">
          <DateField source="start_datetime" showTime />
          <DateField source="end_datetime" showTime />
          <TextField source="eventRecurrence" />
          <TextField source="datetime_string" />
          <TextField multiline source="datetime_array" />
          <TextField source="eventFrequencyType" />
          <TextField source="eventFrequencySetting" />
          <NumberField source="eventFrequencySettingOccurence" />
          <TextField source="eventFrequencySettingOccurenceOptions" />
          <TextField fullWidth multiline source="dateSupportInformation" />
        </Tab>
        <Tab label="image">
          <ImageField source="logo_url" title="Feature Image" />
          <GalleryImagesField multiline fullWidth source="gallery_images" />
        </Tab>
        <Tab label="categories and tags">
          <EventCategories />
          <TextField source="hashtags" />
          <TextField source="targetAudiences" />
        </Tab>
        <Tab label="relationship">
          <TextField source="organizer_uid" />
          <TextField source="organizer.name" />
          <RichTextField fullWidth multiline source="desc" />
          <TextField source="organizer.email" />
          <TextField source="organizer.tel" />
          <TextField source="organizer.logo_url" />
          <TextField source="organizer.facebook_page_name" />
          <TextField source="organizer.facebook_page_url" />
          <TextField source="organizer.instagram_name" />
          <TextField source="organizer.instagram_url" />
          <TextField source="organizer.website_url" />
          <Divider />
          <TextField source="venue_uid" />
          <TextField source="venue.name" />
          <TextField multiline source="venue.address_1" />
          <TextField multiline source="venue.address_2" />
          <TextField source="venue.location.lat" />
          <TextField source="venue.location.lng" />
          <TextField source="venue.city" />
          <TextField source="venue.region" />
          <TextField source="venue.country" />
          <Divider />
          <TextLinkField source="parentUid" linkRsource="events" />
          <TextLinkField source="authorId" linkRsource="users" />
        </Tab>
        <Tab label="status and score">
          <BooleanField label="Disallow sever to update this event?" source="disallowRobotUpdate" />
          <TextField source="lastUpdatedBy" />
          <TextField source="status" />
          <TextField source="reject_reason" />
          <NumberField source="staffScoreAdjustment" />
          <NumberField source="score" fullWidth />
          <ScoreBreakDownField multiline fullWidth source="scoreBreakdown" />
          <TextField source="ical" />
          <DateField source="updated_datetime" showTime />
          <DateField source="created_datetime" showTime />
        </Tab>
        <Tab label="enrolment">
          <TextField source="enrol_type" />
          <DateField source="enrol_start_date" showTime />
          <DateField source="enrol_end_date" showTime />
          <TextField source="enrol_link" />
        </Tab>
        <Tab label="social media and promotion">
          <TextField multiline source="fbLinks" />
          <TextField multiline source="youtubeLinks" />
          <TextField multiline source="igLinks" />
          <TextField multiline source="promotion" />
        </Tab>
        <Tab label="platform">
          <TextField source="platform_source" />
          <DateField source="platform_created_datetime" showTime />
          <DateField source="platform_updated_datetime" showTime />
          <TextField source="platform_status" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
};