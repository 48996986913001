import axios from 'axios';

export default async function approveEvent(uid) {
  try {
    const idToken = window.sessionStorage.getItem('idToken')
    const res = await axios.put(`${process.env.REACT_APP_REST_SERVER_URL}/events/reviews/${uid}`, { isApproved: true }, { headers: { authorization: idToken } });
    return res.data;
  } catch (err) {
    throw err;
  }
}