export const configTypeArray = [
    { id: 'EXCLUSIVE_EVENT', name: 'Exclusive event' },
    { id: 'RECOMMENDED_EVENT', name: 'Recommended event'},
    { id: 'QUICK_FILTER', name: 'Quick filter' },
    { id: 'RECOMMENDED_CATEGORY', name: 'Recommended category'},
    { id: 'SPONSORED_POPULAR_EVENT', name: 'Sponsored popular event'},
    { id: 'SPONSORED_WEEKLY_EVENT', name: 'Sponsored weekly event'},
]

export const languageArray = [
    { id: 'TC', name: '中文' },
    { id: 'EN', name: 'English' },
    { id: 'SC', name: '簡体' },
]