// @ts-ignore
import EventIcon from '@material-ui/icons/Event';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import React from 'react';
import { Admin, Resource, ShowGuesser } from 'react-admin';
import Dashboard from './components/dashboard';
import { EventCreate, EventEdit, EventList, EventShow } from './components/event';
import MyLayout from './components/layout';
import { ScheduledConfigCreate, ScheduledConfigEdit, ScheduledConfigList } from './components/scheduledConfig';
import { UserList, UserShow } from './components/user';
import authProvider from './provider/authProvider';
import dataProvider from './provider/dataProvider';

const App = () => (
  <Admin
    title="Playwhat Admin Site"
    dashboard={Dashboard}
    dataProvider={dataProvider(process.env.REACT_APP_REST_SERVER_URL || '')}
    authProvider={authProvider}
    // customRoutes={customRoutes} 
    layout={MyLayout}>
    <Resource name="events" options={{ label: 'Event' }} list={EventList} edit={EventEdit} create={EventCreate} show={EventShow} icon={EventIcon} />
    <Resource name="scheduledConfigs" options={{ label: 'Scheduled Config' }} list={ScheduledConfigList} create={ScheduledConfigCreate} edit={ScheduledConfigEdit} show={ShowGuesser} icon={PermDataSettingIcon} />
    <Resource name="users" options={{ label: 'User' }} list={UserList} show={UserShow} icon={PermDataSettingIcon} />
  </Admin>
);

export default App;