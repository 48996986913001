import axios from 'axios';

export default async function cloneEvent(uid: string) {
  try {
    const idToken = window.sessionStorage.getItem('idToken')
    const res = await axios.post(`${process.env.REACT_APP_REST_SERVER_URL}/events/clones/${uid}`, null, { headers: { authorization: idToken } });
    return res.data;
  } catch (err) {
    throw err;
  }
}