import { Card, CardContent, CardHeader } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import * as React from "react";
import { useState } from "react";
import getEventStat, { EventStatItem } from '../../api/getEventStat';
import DashboardTableV2 from './tableV2';


const dtFormat = 'YYYY-MM-DDTHH:mm';
function Dashboard() {
  // const dataProvider = useDataProvider();
  const [tableData, setTableData] = useState<{
    from: moment.Moment,
    to: moment.Moment,
    data: EventStatItem[]
  } | null>(null);

  const onSubmit = React.useCallback(async (e: any) => {
    e.preventDefault();
    try {
      setTableData(null);
      const from = moment(e.target[0].value, dtFormat);
      const to = moment(e.target[1].value, dtFormat);
      const data = await getEventStat({ from: from.toISOString(), to: to.toISOString() });
      setTableData({ from, to, data });
    } catch (err) {
      console.error(err);
    }
  }, []);

  React.useEffect(() => {
    const from = moment().startOf('day');
    const to = moment().endOf('day');
    const load = async () => {
      const data = await getEventStat({ from: from.toISOString(), to: to.toISOString() });
      setTableData({ from, to, data });
    }
    load();
  }, [])
  return (
    <Card>
      <CardHeader title="Welcome to the Playwhat Admin site" />
      <CardContent>
        <form noValidate onSubmit={onSubmit}>
          <TextField
            id="start_date"
            label="Start Date"
            type="datetime-local"
            defaultValue={moment().startOf('day').format(dtFormat)}
            required
          />
          <TextField
            id="end_date"
            label="End Date"
            type="datetime-local"
            defaultValue={moment().endOf('day').format(dtFormat)}
            required
          />
          <Button variant="contained" type="submit">Submit</Button>
        </form>
        {
          tableData && (
            <div>
              <h2>{`${tableData.from.format('YYYY/MM/DD HH:mm')} - ${tableData.to.format('YYYY/MM/DD HH:mm')}`}</h2>
              <DashboardTableV2 data={tableData.data} />
            </div>
          )
        }
        {/*   {
          (tableData && tableData.length > 0) ? (tableData.map((data, index) => {
            if (!data) return <div key={`table-${index}`} />
            const { period, from, to, headers, rows } = data;
            return (
              <div key={`table-${index}`}>
                <h2>{`${from.format('YYYY/MM/DD HH:mm')} - ${to.format('YYYY/MM/DD HH:mm')}`}</h2>
                <DashboardTableV2 data={rows} />
              </div>
            )
          })) : (
            <CircularProgress />
          )
        } */}
      </CardContent>
    </Card>
  )
}
export default Dashboard;

