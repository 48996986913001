import axios from 'axios';

export type SetEventScheduledConfigType = 'RECOMMENDED_EVENT' | 'EXCLUSIVE_EVENT'

export interface SetEventScheduledConfigRequest {
  uid?: number;
  priority: number;
  start_date: string;
  end_date: string;
  type: 'RECOMMENDED_EVENT' | 'EXCLUSIVE_EVENT';
  language: string;
  target_uid: string;
}

export default async function setEventScheduledConfig(request: SetEventScheduledConfigRequest) {
  try {
    const idToken = window.sessionStorage.getItem('idToken')
    const { uid } = request;
    if (uid) {
      const res = await axios.put(`${process.env.REACT_APP_REST_SERVER_URL}/scheduledConfigs/${uid}`, request, { headers: { authorization: idToken } });
      return res.data;
    }
    const res = await axios.post(`${process.env.REACT_APP_REST_SERVER_URL}/scheduledConfigs`, request, { headers: { authorization: idToken } });
    return res.data;
  } catch (err) {
    throw err;
  }
}