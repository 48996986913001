import React from 'react';
import { BooleanField, DateField, TextField, Datagrid, List,ReferenceField, EmailField, Show, SimpleShowLayout, ArrayField } from 'react-admin';
import TextLinkField from '../fields/TextLinkField';
export const UserList = props => (
  <List {...props} bulkActionButtons={false} pagination={false} perPage={1000} sort={{ field: 'metadata.lastSignInTime', order: 'DESC' }}>
    <Datagrid rowClick={'show'}>
      <TextField source="id" />
      <EmailField source="email" />
      <TextField source="displayName" />
      <BooleanField source="emailVerified" />
      <BooleanField source="disabled" />
      <DateField source="metadata.lastSignInTime" showTime />
      <DateField source="metadata.creationTime" showTime />
      <DateField source="tokensValidAfterTime" showTime />
      {/* <ArrayField source="providerData"><SingleFieldList><ChipField source="uid" /></SingleFieldList></ArrayField> */}
    </Datagrid>
  </List>
);


export const UserShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <EmailField source="email" />
      <TextField source="displayName" />
      <BooleanField source="emailVerified" />
      <BooleanField source="disabled" />
      <DateField source="metadata.lastSignInTime" showTime />
      <DateField source="metadata.creationTime" showTime />
      <DateField source="tokensValidAfterTime" showTime />
      <ArrayField source="events.data">
        <Datagrid>
     
          <TextLinkField label="uid" source="uid" linkRsource="events" />
          <TextField fullWidth source="slug" helperText="user-friendly ID (shown in the URL)" />
          <TextField fullWidth source="title" />
          <TextField source="status" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);