import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import React, { useMemo } from 'react';
import { EventStatItem } from '../../api/getEventStat';

const columns = [
  { field: 'id', headerName: '#', width: 50 },
  { field: 'platform', headerName: 'Platform', width: 140 },
  { field: 'language', headerName: 'Language', width: 70 },
  { field: 'total_count', headerName: 'Total', width: 100, type: 'number' },
  { field: 'new_count', headerName: 'New', width: 100, type: 'number' },
  { field: 'updated_count', headerName: 'Updated', width: 100, type: 'number' },
  { field: 'approved_count', headerName: 'Approved', width: 100, type: 'number' },
  { field: 'pending_count', headerName: 'Pending', width: 100, type: 'number' },
  { field: 'inaccurate_count', headerName: 'Inaccurate', width: 100, type: 'number' },
  { field: 'error_count', headerName: 'Error', width: 100, type: 'number' },
];

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function DashboardTable({ data = [] }: { data: EventStatItem[] }) {
  const classes = useStyles();
  const rows = useMemo(() => {
    return data.map((item, index) => ({ id: index, ...item }))
  }, [data])
  return (
    <div style={{ width: '100%', height: '1000px' }}>
      <DataGrid rows={rows} columns={columns} pageSize={50} sortModel={[
        {
          field: 'id',
          sort: 'asc',
        },
      ]} />
    </div>
  )
}

export default DashboardTable;