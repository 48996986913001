import { AuthProvider } from 'react-admin';
import {
  FirebaseAuthProvider
} from 'react-admin-firebase';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGGING_SENDER_ID,
};
const authProvider = FirebaseAuthProvider(config, {});
const validEmailSet = new Set(['hkplaywhat@gmail.com', 'daydreamershk@gmail.com'])
const myAuthProvider: AuthProvider = {
  // Copy all authprovider functionality
  ...authProvider,
  // Wrap the login and check for custom claims
  login: async (params) => {
    const user = await authProvider.login(params);
    const idToken = await user.user.getIdToken();
    // getPermissions is how when get the custom claims for the logged in user
    const claims = await authProvider.getPermissions(params);
    const email: string = claims?.email && typeof claims.email === 'string' ? claims.email : '';
    const isAdmin = email.endsWith('@playwhat.hk') || validEmailSet.has(email);
    console.log('login', { user, claims, isAdmin, idToken })
    if (isAdmin && idToken) {
      window.sessionStorage.setItem('idToken', idToken)
      return user;
    }
    // Make sure user is logged out, if not an admin
    await authProvider.logout(params)
    throw new Error("Login error, invalid permissions");
  },
};
export default myAuthProvider