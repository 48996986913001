import React from 'react';
import moment from 'moment';
import { Create, Datagrid, DateField, DateInput, Edit, EditButton, List, NumberField, NumberInput, ReferenceInput, SelectInput, SimpleForm, TextField, TextInput, ShowButton, TabbedForm, FormTab, DateTimeInput, RichTextField, required } from 'react-admin';
import { configTypeArray, languageArray } from './constant';
import ConfigFilter from './listFilter';

export const ScheduledConfigList = props => (
  <List
    filters={<ConfigFilter />}
    bulkActionButtons={false}
    perPage={25}
    sort={{ field: 'updated_datetime', order: 'DESC' }}
    {...props}>
    <Datagrid rowClick="edit">
      <NumberField source="uid" />
      <TextField source="type" />
      <TextField source="target_uid" />
      <TextField source="value" />
      <NumberField source="priority" />
      <DateField source="start_date" showTime />
      <DateField source="end_date" showTime />
      <TextField source="language" />
      <DateField source="updated_datetime" showTime />
    </Datagrid>
  </List>
);

const Aside = ({ record }) => (
  <div style={{ width: 400, margin: '1em' }}>
      <h6>Instruction</h6>
      <div>
        <p>Exclusive event - showing at the homepage. Only 1 event with the highest priority within the effective period is shown.</p>
        <p>Recommended event - showing at the 小編推薦 section at the homepage. Top 6 prioritized events within the effective period are shown.</p>
        <p>Recommended category - showing at the 小編推薦 section at the homepage. If fewer than 6 effective "Recommended events" are set, the system shows the highest-score events which has the Recommended category.</p>
        <p>Quick filter - showing below the homepage search bar. When clicked, navigate to search page with the quick filter words as the searching item.</p>
      </div>
      {record && (
          <>  
              <p>ID: <b>{record.uid}</b></p>
              <small>Last Updated: {moment(record.updated_datetime).format()}</small><br/>
              <small>Created: {moment(record.created_datetime).format()}</small>
          </>
      )}
  </div>
);

export const ScheduledConfigEdit = props => (
  <Edit aside={<Aside />} {...props}>
    <SimpleForm>
      <SelectInput source="type" validate={[required()]} choices={configTypeArray} />
      <TextInput source="target_uid" fullWidth helperText="If type = Exclusive event / Recommended event / Recommended category, fill in the event/category uid" />
      <TextInput source="value" multiline fullWidth helperText="If type = Exclusive event, fill in the exercpt; If type = Quick filter, fill in a comma-separated keywords. If type = Sponsored event, fill in the position 1-6. Otherwise, leave it empty." />
      <NumberInput source="priority" validate={[required()]} min={1} max={100} helperText="1-100, 1 has the highest priority and show first" />
      <DateTimeInput source="start_date" validate={[required()]} helperText="Effective period start date" />
      <DateTimeInput source="end_date" validate={[required()]} helperText="Effective period end date."  />
      <SelectInput source="language" validate={[required()]} choices={languageArray} />
    </SimpleForm>
  </Edit>
);
export const ScheduledConfigCreate = props => (
  <Create aside={<Aside />} {...props}>
    <SimpleForm>
      <SelectInput source="type" validate={[required()]} choices={configTypeArray} defaultValue="RECOMMENDED_EVENT" />
      <TextInput source="target_uid" fullWidth helperText="If type = Exclusive event / Recommended event / Recommended category, fill in the event/category uid" />
      <TextInput source="value" multiline fullWidth helperText="If type = Exclusive event, fill in the exercpt; If type = Quick filter, fill in a comma-separated keywords. If type = Sponsored event, fill in the position 1-6. Otherwise, leave it empty." />
      <NumberInput source="priority" validate={[required()]} min={1} max={100} helperText="1-100, 1 has the highest priority and show first" defaultValue={1} />
      <DateTimeInput source="start_date" validate={[required()]} helperText="Effective period start date" defaultValue={moment({hour: 0, minute: 0, second: 0}).toISOString()}  />
      <DateTimeInput source="end_date" validate={[required()]} helperText="Effective period end date." 
      defaultValue={moment({hour: 16, minute: 0, second: 0}).toISOString()} />
      <SelectInput source="language" validate={[required()]} choices={languageArray} defaultValue="TC" />
    </SimpleForm>
  </Create>
);