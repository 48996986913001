import axios from 'axios';

export interface EventStatItem {
  platform: string;
  language: string;
  total_count: number;
  updated_count: number;
  new_count: number;
  approved_count: number;
  pending_count: number;
  inaccurate_count: number;
  error_count: number;
} 

export default async function getEventStat(option: { from: string, to: string }) {
  try {
    const { from, to } = option;
    const idToken = window.sessionStorage.getItem('idToken')
    const res = await axios.get(`${process.env.REACT_APP_REST_SERVER_URL}/events/statistic`, { params: { from, to }, headers: { authorization: idToken } });
    return res.data.result as EventStatItem[];
  } catch (err) {
    throw err;
  }
}